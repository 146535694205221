import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TransitionStaticImage } from "StaticPages/Components/TransitionImage";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { useWindowSize } from "mui-extensions/src/Hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { css, Global } from "@emotion/react";
import { SkewScrollTriggerAnimation } from "StaticPages/Components/SkewScrollTriggerAnimation";
import { FadeAndSlideScrollTriggerAnimation } from "StaticPages/Components/FadeAndSlideScriollTriggerAnimation";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";

export default () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const size = useWindowSize(500);
    return <>
        <Helmet title="会社概要 | VISUALIZ INC" />
        <Global styles={style} />
        <Layout>
            <main className="Company">
                <PageSummary
                    title={t("会社概要")}
                    subTitle={t("Company Guide")}
                    description={
                        <>
                            {t("VISUALIZが誰が創り何をしている会社なのか")}<br />
                            {t("5W1Hを表形式で")}
                        </>
                    }
                    // eslint-disable-next-line @typescript-eslint/no-var-requires
                    src={require("../../../static/images/AI_top.jpg").default}
                />

                <Grid container
                    sx={{
                        margin: "0 auto",
                        maxWidth: "720px",
                        width: "100%"
                    }}
                    p={{
                        xs: 2,
                        sm: 3
                    }}
                >
                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>名称</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>株式会社VISUALIZ（ヴィジュアライズ）</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>住所</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>広島福山ブランチ</dd>
                                <dd>〒720-0067 広島県 福山市 駅家町倉光 144-1</dd>
                                <dd>福岡ブランチ</dd>
                                <dd>〒810-0004 福岡県 福岡市 中央区渡辺通 5-14-25 coins 3F-03</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>代表者</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>高尾雅史</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>顧問</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>白木大五郎（BERCフェロー）</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>設立</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>2015年11月</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>業務内容</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>
                                    AIを使ったコンテンツの企画制作・アノテーションツール作成・Webシステム制作・ヴィジュアルプログラミングの企画制作・映像制作・VRコンテンツ企画制作
                                </dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>コンタクト</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>福山ブランチ</dd>
                                <dd>info＠visualiz.jp<br />info＠bidnavi.jp(BIDNAVI)<br /><a href="tel:0849595006">Tel : 084-959-5006</a> Fax : 084-959-5016</dd>
                                <dd>福岡ブランチ</dd>
                                <dd>kosaka＠visualiz.jp</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>
                </Grid>

                <Box mt={{ xs: 1, sm: 2 }}>
                    <SkewAnimation
                        in
                    >
                        <Typography
                            variant="h4"
                            component="h2"
                            sx={{
                                letterSpacing: { xs: "0.07em", sm: "0.17em" }
                            }}
                        >
                            {t("沿革")}
                        </Typography>
                    </SkewAnimation>
                </Box>
                <Box>
                    <SkewAnimation
                        in
                    >
                        <Typography
                            variant="body1"
                            component="h2"
                            sx={{
                                letterSpacing: "0.2em",
                                fontFamily: "Jost",
                            }}
                        >
                            {t("History")}
                        </Typography>
                    </SkewAnimation>
                </Box>

                <Grid container
                    sx={{
                        margin: "0 auto",
                        maxWidth: "720px",
                        width: "100%"
                    }}
                    p={{
                        xs: 2,
                        sm: 3
                    }}
                >
                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>平成27年11月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>株式会社VISUALIZ立ち上げ</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>平成28年12月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>三菱電機大阪工場内にて常設ショールームPMを展示</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>平成29年11月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>神奈川県伊勢原市三町にてゴジラのPMを行う（小田急様・東宝様）</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>平成30年7月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>新宿サザンテラスにて2週間PMを行う</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>平成30年10月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>BIDnaviの設計開始</dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>平成31年3月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>
                                    登戸駅にて「ドラえもんたちのいる すこしふしぎな登戸駅」公開
                                    デジタル部門ディレクション・プログラム・撮影・設営など制作一式に携わる
                                    AIシステム開発開始
                                </dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>令和元年8月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>
                                    「ドラえもんのいる すこしふしぎな登戸駅」が
                                    日本空間デザイン賞 エンターテインメント＆クリエイティブ・アート空間 銅賞に選ばれる
                                </dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>令和元年8月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>
                                    小田急エージェンシー様と共同でWEB上で360°映像を体験できる「Satellite Train」を公開
                                </dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>令和2年11月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>
                                    完全無料入札・助成金情報サービス「BIDnavi」を公開
                                </dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>

                    <FadeAndSlideScrollTriggerAnimation
                        style={{ width: "100%" }}
                    >
                        <Grid
                            container
                            p={1}
                        >
                            <Grid item xs={12} sm={3} p={1}>
                                <dt>令和3年4月</dt>
                            </Grid>
                            <Grid item xs={12} sm={9} p={1}>
                                <dd>
                                    福岡ブランチを開設
                                </dd>
                            </Grid>
                        </Grid>
                    </FadeAndSlideScrollTriggerAnimation>
                </Grid>

                <Box mt={20} />
            </main>
        </Layout>
    </>;
};

const style = css`
    dt{
        border-left: 4px solid #212121;
        padding-left: 8px;
        line-height: 1.2;
    }

    dt, dd {
       text-align: left;
       margin: 0;
    }
`;
